const htmlEl = (typeof document !== 'undefined')
	? document.getElementsByTagName('html')[0]
	: null;

export function setMenuOpenClasses(shadowed: boolean): void {
	if (!htmlEl) return;
	setTimeout(() => {
		htmlEl.classList.add('menu-open');
		if (!shadowed) return;
		document.body.classList.add('shadowed');
	}, 25);
}

export function setModalOpenClasses(shadowed: boolean): void {
	if (!htmlEl) return;
	setTimeout(() => {
		htmlEl.classList.add('modal-open');
		if (!shadowed) return;
		document.body.classList.add('shadowed');
	}, 25);
}

export function unsetOpenClasses(): void {
	if (!htmlEl) return;
	htmlEl.classList.remove('menu-open');
	htmlEl.classList.remove('modal-open');
	document.body.classList.remove('shadowed');
}