
import Icon from 'JS/components/Icon.vue';
import { createRadioBase } from './BRadioBase';
import { defineComponent } from 'vue';

const { withRadioBaseProps, useRadioBase } = createRadioBase();

export default defineComponent({
	name: 'BRadioCard',
	components: {
		Icon
	},
	props: {
		...withRadioBaseProps(),
		error: { type: String, required: false, default: '' },
		isInput: { type: Boolean, required: false, default: true },
		ariaDescribedby: String,
	},
	setup(props, { emit }) {
		return {
			...useRadioBase(props, emit),
		};
	}
});
