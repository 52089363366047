
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IGiftCardJson } from 'src/js/types/GiftCard';

@Component({
	name: 'QuickCartGiftCard'
})
export default class QuickCartGiftCard extends Vue {

	/* Props
	============================================*/

	@Prop({ type: Object as () => IGiftCardJson, required: true })
	readonly giftCard: IGiftCardJson;

	/* Computed
	============================================*/

	get imageUrl(): string {
		return this.giftCard.imageUrl ?? this.defaultImage;
	}

	get hasTopPrice(): boolean {
		return this.giftCard.itemDiscount && !this.giftCard.promoEarned;
	}

	get hasBottomPrice(): boolean {
		return this.giftCard.valueTotalText != null && this.hasDiscount;
	}

	get hasDiscount(): boolean {
		return this.giftCard.itemDiscount > 0;
	}

	get giftCardPrice(): string {
		return this.giftCard.lineTotalText;
	}

	/* Data
	============================================*/

	defaultImage: string = '/uploads/gift_card/card_styles/64_ecard.jpg';


}
