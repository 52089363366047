import { FulfillmentType } from './ProductDelivery';
import { IIncomingStockInfo } from './PDP';
import { IPromoOffer } from './Promo';

export interface IProductGroupVariantJson {
	blainNumber: string;
	attributes: Map<string, string>;
	inStoreStock: number;
	onlineStock: number;
	images: Record<string, string>[];
}

export interface IProductGroupSelectorValue {
	value: string;
	swatchUrl: string;
	isSelected: boolean;
	selector: string;
}

export interface IProductGroupSelector {
	selector: string;
	selectorLabel: string;
	values: IProductGroupSelectorValue[];
}

export interface IProductBackInStockInfo {
	isProductEligible: boolean;
	forFulfillmentType: Record<
	| FulfillmentType.InStorePickup
	| FulfillmentType.LocalDelivery
	| FulfillmentType.StandardShipping
	| "any",
	IFulfillmentTypeBackInStockInfo
	>;
}

export interface IFulfillmentTypeBackInStockInfo {
	isAlreadySubscribed: boolean;
}

/**
 * Corresponds to backend's IProductJson type
 */
export interface IProductJson  {
	blainNumber: string;
	brand: string;
	name: string;
	mfgItemNumber: string;
	images: Record<string, string>[];
	features: string[];
	specs: string[];
	aisle: string;
	price: IProductPriceViewModel;
	isClearance: boolean;
	isOnSale: boolean;
	onlineStock: number;
	inStoreStock: number;
	isSoldOut: boolean;
	nearestInStock: string;
	onlineStatus: string;
	shipDelayMessage: string;
	homeDelivery: boolean;
	homeAssembly: boolean;
	liveAnimal: boolean;
	warrantyText: string;
	avgRating: number | null;
	numReviews: number;
	activeOnECommerce: boolean;
	activeInStorePickup: boolean;
	activeLocalDelivery: boolean;
	hideAddToCart: boolean;
	showGetQuote: boolean;
	showOutMarketPickupForm: boolean;
}

type StoreNum = number;
export type Inventory = Record<StoreNum, IProductEffectiveInventory>;

/**
 * Corresponds to backend's ListableProductResource type
 */
export interface IListableProductJson {
	activeInStorePickup: boolean;
	activeLocalDelivery: boolean;
	activeOnECommerce: boolean;
	avgRating: number;
	blainNumber: string;
	brandURL: string;
	compositeName: string; // not used, but populated
	deliveryMessage: string;
	shippingDeliveryDate: string;
	flagColor: string;
	flagText?: string;
	groupID?: number;
	hideStockStatus: boolean;
	imageUrl: string;
	imageUrlWebp: string;
	inventory: Inventory;
	incomingStock: IIncomingStockInfo[] | null;
	isComingSoon: boolean;
	isProductInCart: boolean;
	mapSafePrice: string;
	numReviews: number;
	optionsAvailable: string[];
	price: IProductPrice;
	productName: string;
	productSwatches: IProductSwatch[];
	productUrl: string;
	promo?: IPromoOffer;
	promos: IPromoOffer[];
	sellingPrice: number;
	shippingMessage: string;
	showGetQuote?: boolean;
	showInStoreOnlyMessage: boolean;
	sizeDescription: string; // tire
	speedRating?: string;
	vendorBrand: string;
	warrantyCode: string;
	isAutomotiveBattery: boolean;
	isTire: boolean;
	isAutoPart: boolean;
	tireAttributes: IProductTireAttribute[] | null;
	batteryAttributes: IProductBatteryAttribute[] | null;
	isSponsored: boolean;
	isNew: boolean;
	isBestSeller: boolean;
	isHighestRated: boolean;
	isRestrictedProduct: boolean;
	onlineStock: number;
	inStoreStock: number;
	isSoldOut: boolean;
}

export interface IProductEffectiveInventory {
	blainNumber: string;
	storeNum: number;
	isStocked: boolean;
	storeOnHand: number;
	netOnHand: number;
}

export interface IProductSwatchList {
	[Key: string]: IProductSwatch[];
}

export interface IProductSwatch {
	color: string;
	smallImage: string;
	smallImageWebp: string;
	largeImage: string;
	largeImageWebp: string;
}

/* @deprecated Use IProductPriceViewModel */
export interface IProductPrice {
	blainNumber: string;
	originalPricePrefixText?: string;
	originalPriceSuffixText?: string;
	originalPriceCommentText?: string;
	originalPrice?: string;
	priceSuffixText?: string;
	pricePrefixText?: string;
	priceCommentText?: string;
	priceAltText?: string;
	priceSavingsText?: string;
	priceSavingsTextShortFormat?: string;
	pricePromoText?: string;
	lowPrice?: string;
	highPrice?: string;
	price: string;
	hidePrice?: boolean;
	showTireSetPrice: boolean;
	onlineInStock?: boolean;
	shipDiscountMessage?: string;
	isBelowMAP: boolean;
	mapPrice: number;
	regularPrice: number;
	currentPrice: number;
	rewardsPoints: string;
	discontinued: boolean;
	hideWasPrice: boolean;
	isClearance: boolean;
	isOnSale: boolean;
	strikeThroughWasPrice?: boolean;
	hasSomeVariantsOnSale: boolean;
	priceRangeBelowMap: boolean;
}

export interface IProductCarouselProduct {
	BlainNumber: string;
	ImageUrl: string;
	Name: string;
	AvgRating: number;
	Price: IProductPrice;
	NumReviews: number;
	ProductUrl: string;
	IsOnSale: boolean;
}

export interface IWarranty {
	blainNumber: string;
	price: number;
	min: number;
	max: number;
	name: string;
	warrantyLength: number;
	fixedName: string;
}

// TODO: Add PDP-specific sum-type (backend PdpPriceViewModel) to PDP.ts
/**
 * Corresponds to backend's ProductPriceViewModel type
 */
export interface IProductPriceViewModel {
	blainNumber: string;
	originalPricePrefixText: string | null;
	originalPriceSuffixText: string | null;
	originalPriceCommentText: string | null;
	originalPrice: string | null;
	priceSuffixText: string | null;
	pricePrefixText: string | null;
	priceCommentText: string | null;
	cannotPurchaseOnline: boolean;
	priceAltText: string | null;
	priceSavingsText: string | null;
	pricePromoText: string | null;
	lowPrice: string | null;
	highPrice: string | null;
	price: string;
	hidePrice: boolean;
	showTireSetPrice: boolean;
	onlineInStock: boolean | null;
	shipDiscountMessage: string | null;
	isBelowMAP: boolean;
	mapPrice: number;
	regularPrice: number;
	currentPrice: number;
	rewardsPoints: string;
	discontinued: boolean;
	hideWasPrice: boolean;
	isClearance: boolean;
	strikeThroughWasPrice: boolean;
	isOnSale: boolean;
	hasSomeVariantsOnSale: boolean;
	priceSavingsTextShortFormat: string | null;
	priceRangeBelowMap: boolean;
}

export interface IDisplayPrice {
	dollars: string;
	cents: string;
}

export interface IProductWithAdditionalCharge {
	additionalCharge: IProductAdditionalCharge;
}

export interface IProductAdditionalCharge {
	additionalChargeTotalAmountString: string;
	hasAdditionalCharge: boolean;
	additionalChargeTotalAmount?: number;
	additionalChargeUnitAmount?: number;
	additionalChargeUnitAmountString: string;
	additionalChargeQty?: number;
	hasCoreCharge: boolean;
	additionalChargeFullName: string;
	additionalChargeFixedName: string;
}

export interface IProductTireAttribute {
	blainNumber?: string;
	attributeDescription: string;
	attributeValue: string;
}

export interface IProductBatteryAttribute {
	attributeDescription: string;
	attributeValue: string;
	blainNumber: string;
}

export enum Prop65WarningType {
	Unknown,
	NoWarning,
	Cancer,
	ReproductiveHarm,
	Full
}

export interface PriceProductProp {
	price: IProductPriceViewModel | IProductPrice;
	showGetQuote?: boolean;
	showInStoreOnlyMessage: boolean;
	isComingSoon: boolean;
}

export function getPriceMode(price: IProductPrice | IProductPriceViewModel): string {
	if (price.isClearance) return "Clearance";
	if (price.isOnSale) return "Promo";
	return "Regular";
}