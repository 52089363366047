<template>
	<li :class="menuItem.class ? menuItem.class : itemClass" class="mobile-menu-item">
		<component
			:is="tagName"
			:href="tagName === 'a' ? menuItem.url : 'javascript:void(0)'"
			class="mobile-menu-item__link"
			:class="`mobile-menu-item--${menuItem.id}`"
			:aria-label="tagName === 'button' ? `Open ${menuItem.name} sub-categories` : undefined"
		>
			<div class="left-section flex">
				<span v-if="menuItem.icon" :class="`icon primary-icon icon-${menuItem.icon}`" aria-hidden="true"></span>
				<span>
					{{ menuItem.name }}
				</span>
			</div>
			<div class="right-section flex-row align-items-start">
				<span v-if="menuItem.secondaryText" class="fs-13 mr-tiny medium-bold">
					{{ menuItem.secondaryText }}
				</span>
				<span v-if="tagName === 'button'" class="icon right-icon icon-chevron-right icon-small" aria-hidden="true"></span>
			</div>
		</component>
	</li>
</template>

<script>
export default {
	name: 'MobileMenuItem',
	props: {
		menuItem: {
			type: Object,
			required: true
		},
		itemClass: {
			type: String,
			default: 'secondary'
		}
	},
	computed: {
		tagName() {
			return this.menuItem.url && !this.hasChildren ? 'a' : 'button';
		},
		hasChildren() {
			return (this.menuItem.children?.length ?? 0) > 0;
		},
	}
};
</script>

<style lang='less' scoped>
@import '~@less/_mixins.less';
.mobile-menu-item {
	width: 100%;

	&__link {
		.button-reset();
		padding: 12px 10px;
		text-align: left;
		width: 100%;
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		cursor: pointer;

		color: shade(@CL, 80%);
		text-decoration: none;
		line-height: 19px;
		font-size: 16px;
		font-weight: 500;
	}

	.right-section {
		font-size: 10px;
		span {
			color: @BB;
		}
	}
	.left-section {
		flex: 1;
	}
	&.primary {
		border-bottom: 1px solid tint(@CL, 80%);
		background: white;
		.primary-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			color: @BB;
			width: 15px;
			height: 15px;
			font-size: 20px;
			margin-right: 8px;
		}
		.right-icon {
			color: @BB;
		}
	}
	&.secondary {
		border-bottom: 1px solid tint(@CL, 80%);
		background-color: tint(@CL, 90%);
		.right-icon {
			color: @BB;
		}
	}
}
</style>